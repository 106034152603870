/** @jsxImportSource @emotion/react */
import { Discount } from '@shared/cart-js/src/schemas';
import { useCart } from '@shared/cart-react';
import { calculateCartDiscountAmounts } from '@shared/cart-react/src/functions';
import {
  AnimatePresence,
  MotionConfig,
  motion,
  useSpring,
  useTransform,
} from 'framer-motion';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { roundTo } from '../../../helpers';
import { Dots, SmoothMount } from '../../UI';
import PrettyPrice from '../PrettyPrice';
import { isEmpty } from 'lodash';
import { Checkbox } from '@shared/cove-ui';

const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { delay: 0.35 },
};

const Debug = () => {
  const [debug, setDebug] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cartIdCopied, setCartIdCopied] = useState(false);
  const cart = useCart();

  useEffect(() => {
    setLoading(cart?.loading || false);
  }, [cart]);

  const urlParams = new URLSearchParams(
    typeof window === 'undefined' ? '' : window?.location?.search
  );
  const debugParam = urlParams.get('debug');

  useEffect(() => {
    setDebug(debugParam === 'chucktest');
  }, [debugParam]);

  if (!debug) return null;

  return (
    <MotionConfig transition={{ type: 'spring', duration: 0.3, bounce: 0 }}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        button {
          background: transparent;
        }
        
        p {
          margin: 0;
        }

        :root {
          --tw-translate-x: 0;
          --tw-translate-y: 0;
          --tw-rotate: 0;
          --tw-skew-x: 0deg;
          --tw-skew-y: 0deg;
          --tw-scale-x: 1;
          --tw-scale-y: 1;
        }

        `,
        }}
      />
      <div className="pointer-events-none fixed inset-0 z-[999999] flex items-center justify-center">
        <div
          className="pointer-events-none relative h-full w-full"
          data-loading={loading}
        >
          <AnimatePresence mode="wait">
            {open ? (
              <>
                <motion.div
                  layoutId="debug-container"
                  className={`pointer-events-auto absolute bottom-2 left-2 right-2 top-2 flex flex-col items-start overflow-y-auto bg-orange-100 p-1 shadow`}
                  style={{ borderRadius: 15, backgroundColor: '#ffead1' }}
                >
                  <motion.span
                    layoutId="debug-title"
                    className="flex items-center p-2"
                  >
                    🛒
                    <span
                      className="inline-flex w-min animate-spin"
                      style={{
                        animationPlayState: loading ? 'running' : 'paused',
                      }}
                    >
                      🐛
                    </span>
                    <motion.span {...fadeIn} className="ml-2 text-lg font-bold">
                      Cart Debugger
                    </motion.span>
                    <motion.button
                      {...fadeIn}
                      onClick={() => {
                        if (cart.cartId) {
                          copyToClipboard(cart.cartId, {
                            onCopy: () => {
                              if (!cartIdCopied) {
                                setCartIdCopied(true);
                                setTimeout(() => {
                                  setCartIdCopied(false);
                                }, 3000);
                              }
                            },
                            onError: err => {
                              toast('Error copying to clipboard');
                            },
                          });
                        }
                      }}
                      className="group relative ml-4 flex items-center text-sm font-bold"
                    >
                      {cart.cartId}
                      <span className="absolute left-full ml-2 h-4 w-4 opacity-30 transition-opacity group-hover:opacity-100">
                        <AnimatePresence mode="wait">
                          {cartIdCopied ? (
                            <motion.svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-full w-full"
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0.5 }}
                              key="copied-icon"
                            >
                              <path d="M18 6 7 17l-5-5" />
                              <path d="m22 10-7.5 7.5L13 16" />
                            </motion.svg>
                          ) : (
                            <motion.svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-full w-full"
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              exit={{ opacity: 0, scale: 0.5 }}
                              key="double-check"
                            >
                              <rect
                                width="14"
                                height="14"
                                x="8"
                                y="8"
                                rx="2"
                                ry="2"
                              />
                              <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                            </motion.svg>
                          )}
                        </AnimatePresence>
                      </span>
                    </motion.button>
                  </motion.span>
                  <CartStatus />
                  <motion.div
                    {...fadeIn}
                    className="grid w-full grid-cols-1 gap-2 rounded-sm bg-white p-2 md:grid-cols-2 lg:grid-cols-3"
                  >
                    <CartProducts />
                    <PromoCodes />
                    <CartTotals />
                    <CustomerInfo />
                    <AffirmDebug />
                    <ExportImport />
                  </motion.div>
                </motion.div>
                <motion.button
                  key={`button-open`}
                  type="button"
                  className="pointer-events-auto absolute right-6 top-4 rounded-full bg-orange-100 px-3 py-1"
                  style={{ borderRadius: 15 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => setOpen(false)}
                >
                  Close
                </motion.button>
              </>
            ) : (
              <>
                <motion.div
                  layoutId="debug-container"
                  className="pointer-events-auto absolute right-2 top-2 flex h-8 w-[68px] items-center justify-center overflow-hidden bg-orange-100 px-3 py-1 shadow"
                  style={{ borderRadius: 15 }}
                ></motion.div>
                <motion.button
                  key={`button-closed`}
                  type="button"
                  className="pointer-events-auto absolute right-2 top-2 px-3 py-1"
                  style={{ borderRadius: 15 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => setOpen(true)}
                >
                  <motion.span
                    layoutId="debug-title"
                    className="flex items-center"
                  >
                    🛒
                    <span
                      className="animate-spin"
                      style={{
                        animationPlayState: loading ? 'running' : 'paused',
                      }}
                    >
                      🐛
                    </span>
                  </motion.span>
                </motion.button>
              </>
            )}
          </AnimatePresence>
        </div>
      </div>
    </MotionConfig>
  );
};

const CustomerInfo = () => {
  const cart = useCart();
  const customer = cart.customer;
  const noCustomerInfo = Object.values(customer).every(
    field => field.length === 0
  );
  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <p className="mb-1 text-xl">Customer Info</p>
      <div>
        <div className="flex flex-col overflow-hidden">
          {noCustomerInfo ? (
            <p>No customer info added to cart</p>
          ) : (
            Object.entries(customer)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([key, value]) => {
                return (
                  <div
                    className="border-b-cove-navy-blue flex items-center justify-between gap-1 border-b px-2 py-1 text-sm font-light leading-tight last:border-none"
                    key={key}
                  >
                    <p>{camelCaseToReadable(key)}:</p>
                    <p>{value}</p>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

const AffirmDebug = () => {
  const cart = useCart();
  const { affirmId, affirmAmount, affirmExpires } = cart;
  const noAffirm = !affirmId && !affirmAmount && !affirmExpires;
  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <p className="mb-1 text-xl">Affirm</p>
      <div>
        <div className="flex flex-col overflow-hidden">
          {noAffirm ? (
            <p>Affirm not added to cart</p>
          ) : (
            <>
              <div className="border-b-cove-navy-blue flex items-center justify-between gap-1 border-b px-2 py-1 text-sm font-light leading-tight last:border-none">
                <p>ID:</p>
                <p>{affirmId}</p>
              </div>
              <div className="border-b-cove-navy-blue flex items-center justify-between gap-1 border-b px-2 py-1 text-sm font-light leading-tight last:border-none">
                <p>Amount:</p>
                <p>{affirmAmount}</p>
              </div>
              <div className="border-b-cove-navy-blue flex items-center justify-between gap-1 border-b px-2 py-1 text-sm font-light leading-tight last:border-none">
                <p>Expires:</p>
                <p>{affirmExpires}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
// animated-number.tsx

function AnimatedNumber({
  value,
  decimalPlaces = 0,
}: {
  value: number;
  decimalPlaces?: number;
}) {
  let spring = useSpring(value, { mass: 0.8, stiffness: 75, damping: 15 });
  let display = useTransform(spring, current =>
    current.toFixed(decimalPlaces).toLocaleString()
  );

  useEffect(() => {
    spring.set(value);
  }, [spring, value]);

  return <motion.span>{display}</motion.span>;
}
function roughSizeOfObject(object: any) {
  const jsonString = JSON.stringify(object, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const actualSize = blob.size;
  return actualSize;
}

const formatBytes = (bytes: number): [number, string] => {
  if (bytes === 0) return [0, 'Bytes'];

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return [parseFloat((bytes / Math.pow(k, i)).toFixed(2)), `${sizes[i]}`];
};

const ExportImport = () => {
  const cart = useCart();
  const [includeAllDiscounts, setIncludeAllDiscounts] = useState(false);
  const [includeAllProducts, setIncludeAllProducts] = useState(false);
  const [discountsSize, discountSizeUnit] = formatBytes(
    roughSizeOfObject(cart.discounts)
  );
  const [productsSize, productsSizeUnit] = formatBytes(
    roughSizeOfObject(cart.products)
  );
  const exportData = {
    ...cart,
    discounts: includeAllDiscounts ? cart.discounts : [],
    products: includeAllProducts ? cart.products : {},
  };
  const [cartSize, cartSizeUnit] = formatBytes(roughSizeOfObject(exportData));

  const exportCart = () => {
    // Create cart export data

    // Convert to JSON string
    const jsonString = JSON.stringify(exportData, null, 2);

    // Create blob and download link
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `cart-export-${cart.cartId}-${new Date().toISOString()}.json`;

    // Trigger download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <p className="mb-1 text-xl">Export Cart</p>
      <p className="mb-cove-15 font-light">
        Export the current cart as a JSON file. Choose to include products and
        discounts in the export.
      </p>
      <div className="flex flex-col items-start justify-center gap-1">
        <label className="flex cursor-pointer items-center">
          <Checkbox
            id="include-discounts"
            name="include-discounts"
            onCheckedChange={checked =>
              checked !== 'indeterminate' && setIncludeAllDiscounts(checked)
            }
            checked={includeAllDiscounts}
          />
          Include All Discounts{' '}
          <p className="ml-2 text-sm italic">
            (+
            <AnimatedNumber decimalPlaces={2} value={discountsSize} />{' '}
            {discountSizeUnit})
          </p>
          <div className="group relative ml-2 inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
              <path d="M12 17h.01" />
            </svg>
            <Tooltip side="top">
              Adds discounts fetched from DatoCMS to the export. Including
              discounts will increase the export size by ~{discountsSize}{' '}
              {discountSizeUnit}.
            </Tooltip>
          </div>
        </label>
        <label className="flex cursor-pointer items-center">
          <Checkbox
            id="include-products"
            name="include-products"
            onCheckedChange={checked =>
              checked !== 'indeterminate' && setIncludeAllProducts(checked)
            }
            checked={includeAllProducts}
          />
          Include All Products{' '}
          <p className="ml-2 text-sm italic">
            (+
            <AnimatedNumber decimalPlaces={2} value={productsSize} />{' '}
            {productsSizeUnit})
          </p>
          <div className="group relative ml-2 inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
              <path d="M12 17h.01" />
            </svg>
            <Tooltip side="top">
              Adds products fetched from DatoCMS to the export. Including
              products will increase the export size by ~{productsSize}{' '}
              {productsSizeUnit}.
            </Tooltip>
          </div>
        </label>
        <button
          onClick={exportCart}
          className="bg-cove-navy-blue mt-cove-15 flex items-center gap-2 rounded-full px-3 py-1 text-sm text-white shadow disabled:opacity-50"
          disabled={!cart.cartId}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 17V3" />
            <path d="m6 11 6 6 6-6" />
            <path d="M19 21H5" />
          </svg>
          Export Cart (<AnimatedNumber decimalPlaces={2} value={cartSize} />{' '}
          {cartSizeUnit})
        </button>
        {/* Import functionality placeholder */}
      </div>
    </div>
  );
};

const CartStatus = () => {
  const cart = useCart();
  const [lastSave, setLastSave] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLastSave(cart?.lastSaveDuration || 0);
    setLoading(cart?.loading || false);
  }, [cart]);

  return (
    <motion.div {...fadeIn} className="mb-2 flex items-center gap-2">
      <motion.span
        animate={{
          borderColor: loading ? '#db7a06' : '#047a58',
          backgroundColor: loading ? '#f59e0b' : '#05976a',
        }}
        className={`flex w-24 items-center justify-center gap-2 rounded-full border-2 px-2 py-[1px] text-sm text-white`}
      >
        {loading ? 'Saving' : 'Ready'}
      </motion.span>
      {!loading && lastSave > 0 && (
        <span className="text-sm">
          (Done in {Math.round(lastSave * 100) / 100}ms)
        </span>
      )}
    </motion.div>
  );
};

const PromoCodes = () => {
  const cart = useCart();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [breakdown, setBreakdown] = useState(cart.discountBreakdown || []);

  useEffect(() => {
    if (breakdown?.length !== cart?.couponCodes?.length) {
      setBreakdown(cart?.calculateCouponBreakdown?.() || []);
    }
  }, [cart.cartProducts, cart.couponCodes, cart.couponCodeData]);

  const couponCount = cart?.couponCodes?.length || 0;
  const ghostCouponCount = couponCount - breakdown.length;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (error) {
      timeout = setTimeout(() => {
        setError(null);
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error]);

  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <p className="mb-1 text-xl">Discounts</p>
      <div>
        <p>
          Applied Discounts <i className="text-xs">(Sorted by priority)</i>
        </p>
        <div className="border-cove-navy-blue mb-2 flex flex-col overflow-hidden rounded-sm border">
          <div className="border-cove-navy-blue text-cove-navy-blue/80 grid w-full grid-cols-[1fr_20px_20px_75px_75px] gap-2 border-b px-2 pt-2 text-sm">
            <p className="text-left">Discount</p>
            <div className="group relative flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <Tooltip>Discount is activated via coupon code</Tooltip>
            </div>
            <div className="group relative flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M3 11h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5Zm0 0a9 9 0 1 1 18 0m0 0v5a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3Z" />
                <path d="M21 16v2a4 4 0 0 1-4 4h-5" />
              </svg>
              <Tooltip>Free months of monitoring</Tooltip>
            </div>
            <p className="text-right">Applies to</p>
            <p className="text-right">Amount</p>
          </div>
          <AnimatePresence>
            {(cart?.appliedDiscounts || [])
              .map(discountId => cart.discounts.find(d => d.id === discountId))
              .sort((a, b) => (a?.priority || 1) - (b?.priority || 1))
              .map((discount, index) => {
                if (!discount) return <></>;
                return (
                  <DiscountRow
                    key={discount.id}
                    discountId={discount.id}
                    discount={discount}
                    index={index}
                  />
                );
              })}
          </AnimatePresence>
        </div>
      </div>
      <div>
        <div className="flex justify-between">
          <div className="gap-cove-15 flex items-center">
            Coupon Codes
            <div className="group relative -mt-1 flex items-center gap-1 rounded-full bg-green-400/80 px-2 py-[1px] text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <span>: {Math.max(0, couponCount - ghostCouponCount)}</span>
              <Tooltip>Successfully applied coupons</Tooltip>
            </div>
            <div
              className={`group relative -mt-1 flex items-center gap-1 rounded-full bg-amber-400/80 px-2 py-[1px] text-sm ${ghostCouponCount > 0 ? 'bg-amber-400/80' : 'bg-amber-400/25'}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`h-4 w-4 ${ghostCouponCount > 0 ? 'opacity-100' : 'opacity-25'}`}
              >
                <path d="M9 10h.01" />
                <path d="M15 10h.01" />
                <path d="M12 2a8 8 0 0 0-8 8v12l3-3 2.5 2.5L12 19l2.5 2.5L17 19l3 3V10a8 8 0 0 0-8-8z" />
              </svg>
              <span
                className={`${ghostCouponCount > 0 ? 'opacity-100' : 'opacity-25'}`}
              >
                : {ghostCouponCount}
              </span>
              <Tooltip>
                "Ghost" coupons. These are coupons that have been applied but
                have not been included in the discount breakdown. This could be
                because discounts related to the coupon aren't applied to the
                cart.
              </Tooltip>
            </div>
          </div>
          <button
            type="button"
            onClick={() =>
              cart.couponCodes.forEach(code => cart.removePromo(code))
            }
            className="text-right text-sm disabled:opacity-50"
            disabled={couponCount + ghostCouponCount <= 0}
          >
            Remove All
          </button>
        </div>
        <div className="border-cove-navy-blue min-h-14 overflow-hidden rounded-sm border">
          <div className="text-cove-navy-blue/80 border-cove-navy-blue grid grid-cols-[1fr_80px] gap-2 border-b px-2 pt-2 text-sm font-semibold">
            <p className="truncate">Coupon Code</p>
            <p className="text-right">Amount off</p>
          </div>
          <AnimatePresence>
            {(cart.discountBreakdown || []).map((coupon, index) => {
              return <CouponRow key={index} coupon={coupon} index={index} />;
            })}
          </AnimatePresence>
        </div>
        <form
          onSubmit={async e => {
            e.preventDefault();
            setLoading(true);

            const {
              isActive,
              isValid,
              couponAlreadyApplied,
              allCouponDiscountsAlreadyApplied,
              someCouponDiscountsAlreadyApplied,
              missingProducts,
              couponExclusive,
              minimumTotal,
            } = await cart.applyPromo(code.toUpperCase());

            if (!isValid) {
              setError('Invalid Promo Code');
              setLoading(false);
              return;
            }

            if (!isActive) {
              setError('Promo Code is not active');
              setLoading(false);
              return;
            }

            if (couponAlreadyApplied) {
              setError('Promo Code already applied');
              setLoading(false);
              return;
            }

            if (allCouponDiscountsAlreadyApplied) {
              setError(
                'All discounts related to this promo code have already been applied'
              );
              setLoading(false);
              return;
            }

            if (someCouponDiscountsAlreadyApplied) {
              setError(
                'Some discounts related to this promo code have already been applied'
              );
              setLoading(false);
              return;
            }

            if (minimumTotal > 0) {
              setError(
                'This coupon requires a minimum order total of $' + minimumTotal
              );
              setLoading(false);
              setCode('');
              return;
            }

            if (couponExclusive) {
              setError('Promo Code is exclusive');
              setLoading(false);
              return;
            }

            if (missingProducts.length > 0) {
              setError(`Your cart is not yet eligible for this promo code`);
              setLoading(false);
              return;
            }

            setLoading(false);
            setCode('');
          }}
          className="my-2 flex justify-between gap-2"
        >
          <input
            type="text"
            placeholder="Coupon Code"
            onChange={e => setCode(e.target.value)}
            value={code}
            className="border-b-cove-navy-blue placeholder:text-cove-navy-blue/50 w-full border-b bg-transparent"
            disabled={loading}
          />
          <button
            type="submit"
            disabled={!code.length || loading}
            className="bg-cove-navy-blue w-cove-75 flex h-8 shrink-0 items-center justify-center rounded-full px-3 py-1 text-white shadow disabled:opacity-50"
          >
            {/* @ts-ignore */}
            {loading ? <Dots /> : 'Apply'}
          </button>
        </form>
        <SmoothMount show={!!error} fade className="text-cove-red text-sm">
          {error}
        </SmoothMount>
      </div>
      <div>
        <p>Active Discounts</p>
        <div className="border-cove-navy-blue relative mb-2 flex max-h-[300px] flex-col overflow-y-auto overflow-x-hidden rounded-sm border">
          <div className="border-cove-navy-blue text-cove-navy-blue/80 sticky top-0 z-10 grid w-full grid-cols-[1fr_20px_20px_75px_75px] gap-2 border-b bg-white px-2 pt-2 text-sm">
            <p className="text-left">Discount</p>
            <div className="group relative flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              <Tooltip>Discount is activated via coupon code</Tooltip>
            </div>
            <div className="group relative flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M3 11h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5Zm0 0a9 9 0 1 1 18 0m0 0v5a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3Z" />
                <path d="M21 16v2a4 4 0 0 1-4 4h-5" />
              </svg>
              <Tooltip>Free months of monitoring</Tooltip>
            </div>
            <p className="text-right">Applies to</p>
            <p className="text-right">Amount</p>
          </div>
          <AnimatePresence>
            {(cart?.discounts || [])
              .sort((a, b) => (a?.priority || 1) - (b?.priority || 1))
              .map((discount, index) => {
                if (!discount) return <></>;
                return (
                  <DiscountRow
                    key={discount.id}
                    discountId={discount.id}
                    discount={discount}
                    index={index}
                  />
                );
              })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const DiscountRow = ({
  discountId,
  discount,
  index,
}: {
  discountId: string;
  discount: Discount;
  index: number;
}) => {
  const cart = useCart();
  const products = cart?.products || {};
  const [expanded, setExpanded] = useState(false);
  let relatedCoupon = null;

  if (discount.activateCondition === 'couponCode') {
    relatedCoupon = cart?.coupons?.find(coupon =>
      coupon.discounts.map(d => d.id).includes(discount.id)
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      key={discountId}
    >
      <div
        className={`grid w-full grid-cols-[1fr_20px_20px_75px_75px] items-center gap-2 px-2 text-sm font-normal ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
      >
        <button
          type="button"
          onClick={() => setExpanded(!expanded)}
          className="flex w-full items-center gap-1 text-left text-sm hover:underline"
        >
          <ColorDot text={discountId} />
          <p className="w-full max-w-[170px] truncate">{discountId}</p>
        </button>
        {/* <a
            href={`https://cove-website.admin.datocms.com/editor/item_types/73493/items/${discountId}`}
            target="_blank"
            className="text-cove-blue flex w-auto items-center gap-2 truncate"
          >
            {discountId}
          </a> */}
        <div className="flex items-center justify-center">
          {discount.activateCondition === 'couponCode' && (
            <div className="group relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-4 w-4"
              >
                <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
                <path d="m9 12 2 2 4-4" />
              </svg>
              {relatedCoupon && (
                <Tooltip side="top">{relatedCoupon.couponCode}</Tooltip>
              )}
            </div>
          )}
        </div>
        <p className="flex items-center justify-center">
          {discount.freeMonths}
        </p>
        <p className="text-right">{discount.appliesTo}</p>
        <p className="text-right">
          {discount.discountType === 'percentage' ? (
            `${discount.discountAmount}% off`
          ) : (
            <PrettyPrice price={discount.discountAmount} />
          )}
        </p>
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            key={`product-details-${index}`}
            className="overflow-hidden text-sm"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="pt-0">
              <div
                className={`flex flex-col p-1 pb-3 ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
              >
                <p className="w-full font-semibold">Details:</p>
                <div className="grid grid-cols-2 gap-x-3 gap-y-1 leading-none">
                  <div className="col-span-2 flex w-full items-center justify-between">
                    <p>Activate Condition:</p>
                    <p className="flex items-center font-medium">
                      {relatedCoupon ? (
                        <span className="flex items-center gap-1">
                          <ColorDot text={relatedCoupon.couponCode} />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-4 w-4"
                          >
                            <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
                            <path d="m9 12 2 2 4-4" />
                          </svg>
                          <span className="leading-4">
                            {relatedCoupon.couponCode}
                          </span>
                        </span>
                      ) : (
                        discount.activateCondition
                      )}
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Priority:</p>
                    <p className="font-medium">{discount.priority}</p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Remove from Base:</p>
                    <p className="font-medium">
                      {discount.removeFromBase ? 'Yes' : 'No'}
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Remove from Base Qty:</p>
                    <p className="font-medium">
                      {discount.removeFromBaseQty ? 'Yes' : 'No'}
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Remove from Sale Price:</p>
                    <p className="font-medium">
                      {discount.removeFromSalePrice ? 'Yes' : 'No'}
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Max Quanitity:</p>
                    <p
                      className={`font-medium ${!discount.maxQuantity && 'text-2xl leading-3'}`}
                    >
                      {discount.maxQuantity ?? '∞'}
                    </p>
                  </div>
                  <div className="flex w-full items-center justify-between">
                    <p>Minimum Order Total:</p>
                    <p className="font-medium">
                      <PrettyPrice
                        price={discount.minimumOrderTotalAfterDiscount || 0}
                      />
                    </p>
                  </div>
                  <div className="col-span-2 flex w-full flex-col items-start justify-between gap-1 pt-2">
                    <div className="flex items-center">
                      <p className="font-semibold">Eligible Products</p>
                      <div className="group relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="mx-1 h-4 w-4"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="M12 16v-4" />
                          <path d="M12 8h.01" />
                        </svg>
                        <Tooltip side="top">
                          Discount will only apply to these products
                        </Tooltip>
                      </div>{' '}
                      :
                    </div>
                    <div className="flex w-full flex-wrap gap-[2px]">
                      {discount.restrictTo.length > 0 ? (
                        discount.restrictTo
                          .map(sku => products[sku])
                          .sort((a, b) => a.position - b.position)
                          .map(product => {
                            if (!product) return null;
                            const skuColor = stringToColor(product.sku);
                            return (
                              <p
                                key={`eligible-product-${product.sku}`}
                                className="rounded-sm p-[2px] px-2 leading-4"
                                style={{
                                  backgroundColor: skuColor,
                                }}
                              >
                                {product.name}
                              </p>
                            );
                          })
                      ) : (
                        <p
                          className="rounded-sm p-[2px] px-2 leading-4"
                          style={{
                            backgroundColor: stringToColor('All Products'),
                          }}
                        >
                          All Products
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {discount.conditionProducts.length > 0 && (
                  <div className="flex w-full flex-col items-start justify-between pt-2">
                    <div className="flex items-center">
                      <p className="font-semibold">Conditional Products</p>
                      <div className="group relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="mx-1 h-4 w-4"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="M12 16v-4" />
                          <path d="M12 8h.01" />
                        </svg>
                        <Tooltip side="top">
                          Discount will only apply if{' '}
                          {discount.conditionProductsQuantifier} of the
                          following products are in the cart
                        </Tooltip>
                      </div>{' '}
                      :
                    </div>

                    <div className="flex w-full flex-wrap gap-[2px] pt-1">
                      {discount.conditionProducts
                        .map(sku => products[sku])
                        .sort((a, b) => a.position - b.position)
                        .map(product => {
                          if (!product) return null;
                          const skuColor = stringToColor(product.sku);
                          return (
                            <p
                              key={`conditional-product-${product.sku}`}
                              className="rounded-sm p-[2px] px-2 leading-4"
                              style={{
                                backgroundColor: skuColor,
                              }}
                            >
                              {product.name}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                )}
                {discount.additionalDiscounts.length > 0 && (
                  <div className="flex w-full flex-col items-start justify-between pt-2">
                    <div className="flex items-center">
                      <p className="font-semibold">Additional Discounts</p>
                      <div className="group relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="mx-1 h-4 w-4"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="M12 16v-4" />
                          <path d="M12 8h.01" />
                        </svg>
                        <Tooltip side="top">
                          Additional discounts applied by this discount. Faded
                          discounts have not been applied to the cart because
                          their conditions were not met.
                        </Tooltip>
                      </div>{' '}
                      :
                    </div>
                    <div className="flex w-full flex-wrap gap-[2px]">
                      {discount.additionalDiscounts.map((discount, index) => {
                        const discountColor = stringToColor(discount.id);
                        const isDiscountApplied =
                          cart.appliedDiscounts.includes(discount.id);
                        return (
                          <p
                            key={`additional-discount-${discount.id}`}
                            className={`rounded-sm p-[2px] px-2 leading-4 ${isDiscountApplied ? 'opacity-100' : 'opacity-50'}`}
                            style={{
                              backgroundColor: discountColor,
                            }}
                          >
                            {discount.id}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

const CouponRow = ({
  coupon,
  index,
}: {
  coupon: ArrayElement<
    ReturnType<typeof calculateCartDiscountAmounts>['breakdown']
  >;
  index: number;
}) => {
  const cart = useCart();
  const fullCoupon = cart?.couponCodeData?.[coupon.couponCode] || null;
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const minimumOrderTotal = useMemo(() => {
    const couponDiscounts = Object.keys(coupon.discounts || {});
    let res = 0;
    couponDiscounts.forEach(discountId => {
      const fullDiscount = cart?.discounts.find(d => d.id === discountId);
      if (fullDiscount) {
        const minimumOrderTotalForDiscount =
          fullDiscount.minimumOrderTotalAfterDiscount || 0;
        if (minimumOrderTotalForDiscount > res) {
          res = minimumOrderTotalForDiscount;
        }
      }
    });
    return res;
  }, [cart.discounts, coupon.discounts]);

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      key={coupon.couponCodeId}
    >
      <div
        className={`grid grid-cols-[1fr_80px] gap-2 px-2 ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
      >
        <p className="flex w-full items-center gap-2 truncate">
          <button
            onClick={() => setExpanded(!expanded)}
            type="button"
            className="flex items-center gap-1 truncate text-left text-sm hover:underline"
          >
            <ColorDot text={coupon.couponCode} />
            {coupon.couponCode}
          </button>
          {/* <a
                href={`https://cove-website.admin.datocms.com/editor/item_types/73724/items/${coupon.couponCodeId}`}
                target="_blank"
                className="text-cove-blue flex items-center gap-2 truncate"
              >
                {coupon.couponCode}
              </a> */}
          <button
            onClick={async () => {
              setLoading(true);
              await cart.removePromo(coupon.couponCode);
              setLoading(false);
            }}
            type="button"
            className="ml-2 flex h-4 w-14 items-center justify-center rounded-sm px-2 py-[1px] text-xs transition-all hover:bg-white hover:underline"
            disabled={loading}
          >
            {/* @ts-ignore */}
            {loading ? <Dots dotWidth={4} dotGap={2} /> : 'Remove'}
          </button>
        </p>
        <p className="text-right">
          <PrettyPrice price={coupon.totalSavings} />
        </p>
      </div>
      <AnimatePresence>
        {expanded && fullCoupon && (
          <motion.div
            key={`product-details-${index}`}
            className="overflow-hidden text-sm"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="pt-0">
              <div
                className={`flex flex-col p-1 pb-3 ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
              >
                <div className="gap-cove-15 flex w-full justify-between">
                  <p className="shrink-0">Display Text:</p>
                  <p className="truncate text-right font-medium">
                    {fullCoupon.couponDisplayText}
                  </p>
                </div>
                <div className="gap-cove-15 flex w-full justify-between">
                  <p className="shrink-0">Description:</p>
                  <p className="truncate text-right font-medium">
                    {coupon.description}
                  </p>
                </div>
                <div className="gap-cove-15 flex w-full justify-between">
                  <p>Exclusive:</p>
                  <p className="font-medium">
                    {fullCoupon.exclusive ? 'Yes' : 'No'}
                  </p>
                </div>
                <div className="gap-cove-15 flex w-full justify-between">
                  <p>Exclusive Type:</p>
                  <p className="font-medium">
                    {!!fullCoupon.exclusiveType?.length
                      ? fullCoupon.exclusiveType
                      : 'None'}
                  </p>
                </div>
                <div className="gap-cove-15 flex w-full justify-between">
                  <p>Single Use:</p>
                  <p className="font-medium">
                    {fullCoupon.singleUse ? 'Yes' : 'No'}
                  </p>
                </div>
                <div className="gap-cove-15 flex w-full justify-between">
                  <p>Minimum Order Total:</p>
                  <p className="font-medium">
                    <PrettyPrice price={minimumOrderTotal} />
                  </p>
                </div>

                {fullCoupon.discounts.length > 0 && (
                  <div className="flex w-full flex-col justify-between pt-2">
                    <p className="font-semibold">Discounts:</p>
                    <div className="flex w-full flex-wrap gap-[2px]">
                      {fullCoupon.discounts.map((discount, index) => {
                        const discountColor = stringToColor(discount.id);
                        return (
                          <p
                            key={`additional-discount-${discount.id}`}
                            className="rounded-sm p-[2px] px-2 leading-4"
                            style={{
                              backgroundColor: discountColor,
                            }}
                          >
                            {discount.id}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const importantTotals = [
  'equipmentRetailTotal',
  'equipmentTotal',
  'equipmentTotalWithTax',
  'monitoringRetailTotal',
  'monitoringTotal',
  'monitoringTotalWithTax',
  'orderRetailTotal',
  'orderTotal',
  'orderTotalWithTax',
];

const CartTotals = () => {
  const [showAll, setShowAll] = useState(false);
  const [zip, setZip] = useState('');
  const cart = useCart();
  const totals = cart?.totals?.totals || {};

  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <p className="mb-1 text-xl">Totals</p>
      <div className="border-cove-navy-blue mb-2 flex flex-col overflow-hidden rounded-sm border">
        <div className="border-cove-navy-blue text-cove-navy-blue/80 grid w-full grid-cols-[1fr_80px] gap-2 border-b px-2 pt-2 text-sm">
          <p className="text-left">Main Total</p>
          <p className="text-right">Amount</p>
        </div>
        {importantTotals.map((total, index) => {
          return (
            <div
              key={total}
              className={`grid grid-cols-[1fr_80px] gap-2 px-2 text-sm ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
            >
              <p className="flex items-center truncate">{total}</p>
              <p className="text-right font-semibold">
                <PrettyPrice
                  price={parseFloat(
                    `${totals[total as keyof typeof totals] || 0}`
                  )}
                />
              </p>
            </div>
          );
        })}
      </div>
      <div className="text-sm">
        <div className="flex items-center justify-between gap-2">
          <p>
            Tax has {!cart.taxCalculated && 'not'} been calculated{' '}
            {cart.taxCalculated && 'using ZIP code:'}
          </p>
          <p>{cart.taxCalculated && cart?.taxPostcode}</p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p>Equipment Tax Rate:</p>{' '}
          <p>{roundTo((cart?.equipmentTaxRate || 0) * 100)}%</p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p>Monitoring Tax Rate:</p>{' '}
          <p>{roundTo((cart?.monitoringTaxRate || 0) * 100)}%</p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p>Free Months:</p> <p>{cart?.freeMonths}</p>
        </div>
      </div>
      <form
        onSubmit={async e => {
          e.preventDefault();
          await cart.calculateTax(zip);
          setZip('');
        }}
        className="flex justify-between gap-2"
      >
        <input
          type="text"
          placeholder="Zipcode"
          onChange={e => setZip(e.target.value)}
          value={zip}
          className="border-b-cove-navy-blue placeholder:text-cove-navy-blue w-full border-b bg-transparent"
        />
        <button
          type="submit"
          disabled={!zip.length}
          className="bg-cove-navy-blue shrink-0 rounded-full px-3 py-1 text-white shadow disabled:opacity-50"
        >
          Calculate Tax
        </button>
      </form>
      <button
        type="button"
        onClick={() => setShowAll(!showAll)}
        className="text-cove-navy-blue shrink-0 hover:underline disabled:opacity-50"
      >
        {showAll ? 'Hide' : 'Show'} All Totals
      </button>
      <AnimatePresence>
        {showAll && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-hidden"
          >
            <div className="border-cove-navy-blue mb-2 flex flex-col overflow-hidden rounded-sm border">
              <div className="border-cove-navy-blue text-cove-navy-blue/80 grid w-full grid-cols-[1fr_80px] gap-2 border-b px-2 pt-2 text-sm">
                <p className="text-left">Equipment</p>
                <p className="text-right">Amount</p>
              </div>
              {Object.entries(totals)
                .filter(([label]) => label.startsWith('equipment'))
                .map(([label, value], index) => {
                  return (
                    <div
                      key={label}
                      className={`grid grid-cols-[1fr_80px] gap-2 px-2 text-sm ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
                    >
                      <p className="flex items-center truncate">{label}</p>
                      <p className="text-right font-semibold">
                        <PrettyPrice price={parseFloat(`${value || 0}`)} />
                      </p>
                    </div>
                  );
                })}
            </div>

            <div className="border-cove-navy-blue mb-2 flex flex-col overflow-hidden rounded-sm border">
              <div className="border-cove-navy-blue text-cove-navy-blue/80 grid w-full grid-cols-[1fr_80px] gap-2 border-b px-2 pt-2 text-sm">
                <p className="text-left">Monitoring</p>
                <p className="text-right">Amount</p>
              </div>
              {Object.entries(totals)
                .filter(([label]) => label.startsWith('monitoring'))
                .map(([label, value], index) => {
                  return (
                    <div
                      key={label}
                      className={`grid grid-cols-[1fr_80px] gap-2 px-2 text-sm ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
                    >
                      <p className="flex items-center truncate">{label}</p>
                      <p className="text-right font-semibold">
                        <PrettyPrice price={parseFloat(`${value || 0}`)} />
                      </p>
                    </div>
                  );
                })}
            </div>

            <div className="border-cove-navy-blue flex flex-col overflow-hidden rounded-sm border">
              <div className="border-cove-navy-blue text-cove-navy-blue/80 grid w-full grid-cols-[1fr_80px] gap-2 border-b px-2 pt-2 text-sm">
                <p className="text-left">Order</p>
                <p className="text-right">Amount</p>
              </div>
              {Object.entries(totals)
                .filter(([label]) => label.startsWith('order'))
                .map(([label, value], index) => {
                  return (
                    <div
                      key={label}
                      className={`grid grid-cols-[1fr_80px] gap-2 px-2 text-sm ${index % 2 === 0 ? 'bg-cove-navy-blue/10' : ''}`}
                    >
                      <p className="flex items-center truncate">{label}</p>
                      <p className="text-right font-semibold">
                        <PrettyPrice price={parseFloat(`${value || 0}`)} />
                      </p>
                    </div>
                  );
                })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const dontShow = ['vm-pl', 'bm-pl'];

const CartProducts = () => {
  const cart = useCart();
  const [validateResponse, setValidateResponse] = useState<ReturnType<
    typeof cart.validate
  > | null>(null);

  const createSampleCart = () => {
    cart.validate({ checkCart: true, defaultCart: true });
    const baseProducts = [
      'CPA-HUB-345-1',
      'CPA-PANEL-345-1',
      'CP-YS-23i-1',
      'window-sticker-sm',
    ];
    const otherProducts = Object.values(cart?.products || {})?.filter(
      product => {
        return (
          !baseProducts.includes(product.sku) &&
          product.productType === 'equipment'
        );
      }
    );

    const toAdd = [];
    for (const product of otherProducts) {
      const addToCart = Math.random() > 0.6;
      if (addToCart) {
        const qty =
          Math.floor(Math.random() * product.maximumSensorCountOnSite) + 1;

        toAdd.push({
          sku: product.sku,
          qty,
        });
      }
    }

    cart.updateCartItems(toAdd);
  };

  return (
    <div className="border-cove-navy-blue flex w-full flex-col gap-1 rounded-sm border p-1">
      <div className="flex items-center justify-between">
        <p className="mb-1 text-xl">Products</p>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              const res = cart.validate({
                checkCart: true,
                defaultCart: true,
                checkCustomer: true,
                checkMonitoring: true,
              });
              setValidateResponse(res);
            }}
            disabled={cart.loading}
            type="button"
            className="bg-cove-navy-blue flex h-5 shrink-0 items-center justify-center rounded-full px-2 text-sm text-white shadow disabled:opacity-50"
          >
            Validate Cart
          </button>
          <button
            onClick={createSampleCart}
            type="button"
            disabled={cart.loading}
            className="bg-cove-navy-blue flex h-5 shrink-0 items-center justify-center rounded-full px-2 text-sm text-white shadow disabled:opacity-50"
          >
            Create Sample Cart
          </button>
        </div>
      </div>
      <SmoothMount show={!!validateResponse}>
        <div className="flex flex-col gap-1 p-4">
          <p className="flex items-center gap-2">
            {validateResponse?.emptyCart ? (
              <>
                <WarningIcon />
                <span className="text-sm leading-none">Cart is empty</span>
              </>
            ) : (
              <>
                <CheckIcon />
                <span className="text-sm leading-none">Cart is not empty</span>
              </>
            )}
          </p>
          <p className="flex items-center gap-2">
            {validateResponse?.noSensors ? (
              <>
                <WarningIcon />
                <span className="text-sm leading-none">
                  Cart has no sensors
                </span>
              </>
            ) : (
              <>
                <CheckIcon />
                <span className="text-sm leading-none">Cart has sensors</span>
              </>
            )}
          </p>
          <p className="flex items-center gap-2">
            {validateResponse?.missingMonitoring ? (
              <>
                <WarningIcon />
                <span className="text-sm leading-none">
                  Cart is missing monitoring plan
                </span>
              </>
            ) : (
              <>
                <CheckIcon />
                <span className="text-sm leading-none">
                  Cart has monitoring plan
                </span>
              </>
            )}
          </p>
          <p className="flex items-center gap-2">
            {validateResponse?.invalidCustomerInfo ? (
              <>
                <WarningIcon />
                <span className="text-sm leading-none">
                  Cart has invalid customer info
                </span>
              </>
            ) : (
              <>
                <CheckIcon />
                <span className="text-sm leading-none">
                  Cart has valid customer info
                </span>
              </>
            )}
          </p>
        </div>
      </SmoothMount>
      <div className="text-cove-navy-blue/80 grid grid-cols-[1fr_75px] items-center gap-2 text-sm">
        <p className="truncate text-left">Product</p>
        <div className="flex items-center gap-1">
          <span className="inline-block w-24 text-center">Qty</span>
        </div>
      </div>
      {cart.products &&
        Object.values(cart.products)
          .sort((a, b) => a.position - b.position)
          .filter(product => !dontShow.includes(product.sku))
          .map((product, index) => (
            <CartProductRow key={index} product={product} index={index} />
          ))}
    </div>
  );
};

const WarningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="text-amber-400/80"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  );
};
const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="text-green-400/80"
    >
      <path d="M20 6 9 17l-5-5" />
    </svg>
  );
};

const CartProductRow = ({
  product,
  index,
}: {
  product: any;
  index: number;
}) => {
  const [expanded, setExpanded] = useState(false);
  const cart = useCart();
  const cartProduct = cart?.cartProducts?.[product.sku];

  return (
    <div
      key={`product-${index}`}
      className={`flex w-full flex-col ${index === 0 ? '' : 'border-cove-navy-blue border-t pt-1'}`}
    >
      <div className="grid grid-cols-[1fr_75px] items-center gap-2">
        <button
          onClick={() => setExpanded(!expanded)}
          type="button"
          className="flex items-center gap-1 truncate text-left text-sm hover:underline"
        >
          <ColorDot text={product.sku} />
          {product.name}
        </button>
        <div className="flex items-center gap-1">
          <button
            onClick={() =>
              cart.updateCartItem({
                sku: product.sku,
                qty: Math.max(0, (product.qty || 0) - 1),
              })
            }
            disabled={(product.qty || 0) === 0}
            type="button"
            className="bg-cove-navy-blue flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-white shadow disabled:opacity-50"
          >
            -
          </button>
          <span className="inline-block w-24 text-center">
            {product.qty || 0}
          </span>
          <button
            onClick={() =>
              cart.updateCartItem({
                sku: product.sku,
                qty: Math.min(
                  product.maximumSensorCountOnSite,
                  (product.qty || 0) + 1
                ),
              })
            }
            disabled={product.maximumSensorCountOnSite === product.qty}
            type="button"
            className="bg-cove-navy-blue flex h-5 w-5 shrink-0 items-center justify-center rounded-full text-white shadow disabled:opacity-50"
          >
            +
          </button>
        </div>
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div
            key={`product-details-${index}`}
            className="overflow-hidden text-sm"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="pt-2">
              <div
                className="flex flex-col rounded-sm p-1"
                style={{
                  backgroundColor: stringToColor(product.sku),
                }}
              >
                <div className="flex w-full items-center justify-between gap-1 pt-1">
                  <p>SKU:</p>
                  <p className="flex items-center gap-1">{product.sku}</p>
                </div>

                <div className="flex w-full justify-between gap-1 pt-1">
                  <p>Pre Sale:</p>
                  <p>
                    <PrettyPrice price={cartProduct?.baseTotal || 0} />
                  </p>
                </div>
                <AnimatePresence>
                  {Object.entries(cartProduct?.discountMap || {}).length >
                    0 && (
                    <motion.p
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="m-0 text-xs"
                      transition={{ delay: 0.3 }}
                    >
                      Product Discounts
                    </motion.p>
                  )}
                </AnimatePresence>
                <AnimatePresence>
                  {Object.entries(cartProduct?.discountMap || {})
                    .sort((a, b) => b[1] - a[1])
                    .map(([discountId, discountAmount], index) => {
                      console.log({ cartProduct, discountId, discountAmount });
                      return (
                        <motion.div
                          key={discountId + index}
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: 'auto' }}
                          exit={{ opacity: 0, height: 0 }}
                          className="overflow-hidden"
                          transition={{ delay: 0.3 }}
                        >
                          <div className="flex w-full justify-between gap-1 pl-2">
                            <a
                              href={`https://cove-website.admin.datocms.com/editor/item_types/73493/items/${discountId}`}
                              target="_blank"
                              className="text-cove-blue flex items-center gap-1 truncate underline"
                            >
                              <ColorDot text={discountId} />
                              {discountId}:
                            </a>
                            <p className="text-cove-red">
                              -<PrettyPrice price={discountAmount} />
                            </p>
                          </div>
                        </motion.div>
                      );
                    })}
                </AnimatePresence>
                <div className="flex w-full justify-between gap-1 pt-1">
                  <p>Final:</p>
                  <p>
                    <PrettyPrice price={cartProduct?.total || 0} />
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ColorDot = ({ text }: { text: string }) => {
  const color = stringToColor(text);
  return (
    <span
      className="inline-block h-3 w-3 shrink-0 rounded-full border border-black"
      style={{
        backgroundColor: color,
      }}
    ></span>
  );
};

const Tooltip = ({
  side = 'bottom',
  children,
}: {
  side?: 'top' | 'bottom';
  children: ReactNode;
}) => {
  return (
    <div
      className={`${side === 'top' ? 'before:border-r-cove-navy-blue before:border-b-cove-navy-blue bottom-full -translate-y-1 before:top-full before:-translate-y-1/2 before:border-b before:border-r' : 'before:border-l-cove-navy-blue before:border-t-cove-navy-blue top-full translate-y-1 before:bottom-full before:translate-y-1/2 before:border-l before:border-t'} text-cove-navy-blue border-cove-navy-blue  pointer-events-none absolute left-1/2 z-10 flex min-h-8 w-64 -translate-x-1/2 flex-col justify-center rounded-sm border bg-white px-2 py-1 text-sm font-semibold leading-none opacity-0 shadow transition-opacity before:absolute before:left-1/2 before:h-3 before:w-3 before:-translate-x-1/2 before:rotate-45 before:bg-white before:content-[''] group-hover:opacity-100`}
    >
      {children}
    </div>
  );
};

function ensureBrightness(hex: string, minBrightness: number): string {
  hex = hex.replace(/^#/, '');
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  let brightness = (r * 299 + g * 587 + b * 114) / 1000;
  if (brightness < minBrightness) {
    const factor = minBrightness / brightness;
    r = Math.min(255, Math.round(r * factor));
    g = Math.min(255, Math.round(g * factor));
    b = Math.min(255, Math.round(b * factor));
  }

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}4D`;
}

const stringToColor = function (
  str: string,
  minBrightness: number = 100
): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  return ensureBrightness(colour, minBrightness);
};

function camelCaseToReadable(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters
    .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter of the string
}

function copyToClipboard(
  text: string,
  { onCopy, onError }: { onCopy?: () => void; onError?: (err: unknown) => void }
) {
  navigator.clipboard.writeText(text).then(
    () => {
      onCopy?.();
    },
    err => {
      onError?.(err);
    }
  );
}

export default Debug;
